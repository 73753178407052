<template>
  <v-container fluid>
    <v-card flat :loading="loading">
      <v-card-title class="subtitle primary--text">
        RELATÓRIO DE EXAMES
        <v-spacer></v-spacer>
        <div v-if="loadingReport">
          Processando dados...
          <v-progress-circular :size="24" class="mr-4" color="primary" indeterminate></v-progress-circular>
        </div>
        <div v-else>
          <v-btn small link color="primary" class="ml-2 text-capitalize" @click="getExames"> Atualizar Gráfico </v-btn>
          <v-btn small link color="primary" class="ml-2 text-capitalize" @click="getExcel">Download Excel</v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <pie :option="optionModalidePie"></pie>
          </v-col>
          <v-col cols="6">
            <bar :option="optionTipoExameBar"></bar>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-text>
        <table class="lista-exames-report">
          <thead>
            <td>Data</td>
            <td>Nome</td>
            <td>Modalidade</td>
            <td>Tipo Exame</td>
          </thead>
          <tbody>
            <tr v-for="exame in items" :key="exame.id">
              <td>
                {{ parseDate(exame.data) }}
              </td>
              <td>
                {{ exame.solicitacao.paciente.nome }}
              </td>
              <td v-if="exame.tipoExame"> {{ exame.tipoExame.modalidade.sigla }}</td>
              <td v-if="exame.tipoExame"> {{ exame.tipoExame.nome }}</td>
            </tr>
          </tbody>
        </table>
      </v-card-text> -->
    </v-card>
    <alerta />
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Pie from '@/components/charts/pie.vue';
import Bar from "../../components/charts/bar.vue";
import axios from "axios"
import Notification from "../../models/Notification"


export default {
  name: "relatorio-atendimento-export-create-edit",
  data() {
    return {
      items: [],
      loading: false,
      loadingReport: false,
      optionModalidePie: {},
      optionTipoExameBar: {},
      cancelSource: null,
    };
  },
  components: {
    Alerta,
    Pie,
    Bar
  },
  computed: {
    ...mapGetters(["reportsQuery", "reportsFilters"]),
  },
  watch: {
    reportsFilters() {
      this.searchByFilters();
    },
  },
  methods: {
    ...mapActions(["setAlerta", "setUltimaTela", "setShowReportsFilters", "addNotifications"]),
    parseDate(value) {
      if (value) return moment(value).format("DD/MM/YYYY");
      return value;
    },
    searchByFilters() {
      this.items = [];
      this.getExames();
    },
    async getExames() {
      const self = this;

      // TODO: Cancelar a requisição anterior caso esteja pendente.
      if (self.cancelSource) {
        self.cancelSource.cancel();
      }
      // TODO: Realiza a nova requisição para contagem dos registros filtrados.
      self.cancelSource = axios.CancelToken.source();

      self.loading = true;
      self.loadingReport = true

      const urlFilter = (this.reportsFilters.urlFilter ? this.reportsFilters.urlFilter : "")
      self.items = await self.$http
        .get(`/exame?max=200${urlFilter}`, {
          cancelToken: self.cancelSource.token,
        })
        .then((response) => response?.data?.content || [])
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Request cancelled: ", error);
          } else {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao buscar os exames!",
            });
          }
          return [];
        });

      this.modalidadeDataBuilder()
      this.tipoExameDataBuilder()

      self.loading = false;
      self.loadingReport = false
    },
    getExcel() {
      const self = this
      self.loadingReport = true

      const urlFilter = (this.reportsFilters.urlFilter ? this.reportsFilters.urlFilter : "").replace(/&/, "?");
      const callback = self.$http.get(`/exameDataReport/reports/excel${urlFilter}`, { responseType: 'arraybuffer', timeout: 0 })
        .then((response) => {
          self.loadingReport = false

          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          // Generate a URL for the Blob object
          this.linkPdf = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.id = "laudo1";
          // link.target = `laudo_${self.exame.id}`
          link.href = this.linkPdf;
          // Set the desired filename
          link.setAttribute('download', 'Exames.xlsx')
          // link.download = 'file.pdf'; // Name of the downloaded file
          // link.click(); // Excecute link

          // Clean up by revoking the Object URL
          // URL.revokeObjectURL(this.linkPdf);

          // Return link maked
          return new Notification("xlsx", "Relatório gerado com sucesso", link, this.$options.name, this.reportsFilters)
        })
        .catch((e) => {
          self.loadingReport = false
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: e?.response?.data?.message || "Não foi possível gerar o relatório!"
          })
          return new Notification("error", "Não foi possível gerar o relatório!", e, this.$options.name, this.reportsFilters)
        });

      // Send function to state variable notifications  
      self.addNotifications(callback)
    },
    openReportsFilter(value) {
      this.setShowReportsFilters(value)
    },
    parseData() {
      this.data = this.getExames().map((exame) => {
        return {
          data: exame.data,                  
        }
      });
    },
    modalidadeDataBuilder() {
      const contagemPorModalidade = this.items.reduce((acumulador, exame) => {
        if (!exame.tipoExame) return acumulador;
        const modalidade = exame.tipoExame.modalidade.sigla;
        if (!acumulador[modalidade]) {
          acumulador[modalidade] = 1;
        } else {
          acumulador[modalidade]++;
        }
        return acumulador;
      }, {});
      const data = Object.keys(contagemPorModalidade).map(modalidade => {
        return { name: modalidade, value: contagemPorModalidade[modalidade] };
      });
      this.optionModalidePie = {
        title: {
          text: 'Modalidades',
          // subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Modalidade',
            type: 'pie',
            radius: '60%',
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    },
    tipoExameDataBuilder() {
      const contagemPorTipoExame = this.items.reduce((acumulador, exame) => {
        if (!exame.tipoExame) return acumulador;
        const tipoExame = exame.tipoExame.nome;
        if (!acumulador[tipoExame]) {
          acumulador[tipoExame] = 1;
        } else {
          acumulador[tipoExame]++;
        }
        return acumulador;
      }, {});
      
      let data = Object.keys(contagemPorTipoExame).map(tipoExame => {
        return { name: tipoExame, value: contagemPorTipoExame[tipoExame] };
      });

      data.sort((a, b) => b.value - a.value);

      console.log(Object.keys(data));
      this.optionTipoExameBar = {
        title: {
          text: 'Exames por Tipo',
          left: 'center'
        },
        tooltip: {},
        legend: {
          data: ['Tipo Exame'],
          orient: 'vertical',
          left: 'left'
        },

        xAxis: {
          data: Object.keys(data)
        },
        yAxis: {},
        series: [
          {
            name: 'Tipo Exame',
            type: 'bar',
            data: Object.values(data)
          }
        ]
      }
    }
  },
  mounted() {
    this.openReportsFilter(true);
  },
  created() {
    this.setUltimaTela({ tela: this.$route.fullPath });
  },
  destroyed() {
    this.openReportsFilter(false);
  },
};
</script>

<style scoped>
.lista-exames-report {
  width: 100%;
}

.lista-exames-report thead {
  text-transform: uppercase;
  font-weight: bold;
}
</style>
