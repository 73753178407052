<template>
  <v-card-text :id="id" :style="{ 'height': height }">
  </v-card-text>
</template>

<script>

import * as echarts from 'echarts';

export default {
  name: "bar",
  props: {
    height: {
      type: String,
      default: "500px"
    },
    option: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      id: `echarts-bar_${Math.random().toString(16).slice(2)}`,
    }
  },
  watch: {
    option() {
      this.updateChart()
    }
  },
  methods: {
    updateChart() {
      this.myChart.setOption(this.option);
    }
  },
  mounted() {
    const dom = window.document.getElementById(this.id);
    this.myChart = echarts.init(dom, 'walden');
    this.updateChart()
  }

}
</script>

<style></style>
